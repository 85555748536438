<template>
  <div
    class="columns is-multiline is-marginless has-padding-50 has-padding-bottom-400"
  >
    <div class="column is-12">
      <collapse title="Details">
        <contract-details
          :contract="contract"
          admin-context
          @updated="tableKey++"
        />
      </collapse>
    </div>

    <div class="column is-12">
      <collapse title="Invoices">
        <invoices-table
          v-if="contract"
          :key="`invoices-${tableKey}`"
          :contract-id="contractId"
        />
      </collapse>
    </div>
    <div class="column is-12">
      <collapse title="Logs">
        <logs-table
          :key="`logs-${tableKey}`"
          :contract-id="contractId"
          :allow-create="false"
          :event-filter="event => $_.includes(event.value, 'contract')"
          admin-context
        />
      </collapse>
    </div>
  </div>
</template>

<script>
export default {
  name: "AdminContract",
  components: {
    "contract-details": () => import("@shared/contracts/_contractDetails"),
    "invoices-table": () => import("@shared/invoices/_invoicesTable"),
    "logs-table": () => import("@shared/logs/_logsTable")
  },
  data() {
    return {
      tableKey: 0,
      contractId: this.$route.params.contractId
    };
  },
  computed: {
    contract() {
      return this.$store.getters["billing/contract"](this.contractId) || {};
    }
  },
  created() {
    this.observeContract();
  },
  beforeDestroy() {
    this.unobserveContract();
  },
  methods: {
    observeContract() {
      this.$store
        .dispatch("billing/observeContract", {
          siteId: this.contractId
        })
        .then(contract => {
          if (contract && !contract.exists()) this.contractNotFound();
        })
        .catch(() => {
          this.contractNotFound();
        });
    },
    unobserveContract() {
      this.$store.dispatch("billing/unobserveContract", {
        siteId: this.contractId
      });
    },
    contractNotFound() {
      this.$router.push(`/admin/contracts`);
      this.$toast.open({
        message: `Contract not found`,
        type: "is-danger"
      });
    }
  }
};
</script>
